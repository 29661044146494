import React from 'react';
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

export default function useBreakPoints() {
    const {xs, sm, md, lg, xl, xxl} = useBreakpoint()

    return {
        isXsBreakPoint: xs,
        isSmBreakPoint: sm,
        isMdBreakPoint: md,
        isLgBreakPoint: lg,
        isXlBreakPoint: xl,
        isXxlBreakPoint: xxl,
    }
}