import React from 'react';
import styled from 'styled-components'
import {Col, Image, Row} from "antd";
import SubTitle from "./SubTitle";
import { NavLink} from "react-router-dom";
import DateNews from "./DateNews";



const StyledNavLink = styled(NavLink)`

  color: black;

  &:hover {
    color: black;
  }
`


export default function CardRecentNews({image, date, title, slug}) {


    return (
        <StyledNavLink to={`/blog/${slug}`}>
            {/*<Container>*/}
            <Row gutter={[8, 8]}>
                <Col xs={6}> <Image  src={image} preview={false}/></Col>
                <Col xs={18}>
                    <DateNews value={date}/>
                    <SubTitle level={5}
                              ellipsis={{
                                  rows: 2,
                                  expandable: false,
                              }}>{title}</SubTitle></Col>

            </Row>


            {/*</Container>*/}
        </StyledNavLink>
    )
}