import React from 'react'
import styled from "styled-components";
import {Col, Layout, Row, Space, Typography, Image, Button} from "antd";

import {NavLink} from "react-router-dom";
import {GRAY_2, GREE_1} from "../utils/colors";
import Logo from "../assets/imgs/logo.png";
import {
    TwitterOutlined,
    YoutubeFilled,
    InstagramFilled,
    PhoneFilled,
    MailFilled,
    WhatsAppOutlined,
    ShopFilled
} from '@ant-design/icons'
import ContanctIcon from "./ContanctIcon";
import ContentContainer from "./ContentContainer";
import {
    NAV_ITEMS,
    URL_INSTAGRAM, URL_TWITTER,
    URL_WHATSAPP,
    URL_YOUTUBE
} from "../settings";
import Section from "./Section";
import ImageBackground from "./ImageBackground";
import ImageLocationBG from "../assets/imgs/location_bg.jpg";
import BannerWhite from "../assets/imgs/bg_2.jpg";
import QRCodeImage from "../assets/imgs/qr_code_px.png";
import MainTitle from "./MainTitle";
import Text from "./Text";
import Title from "./Title";

const {Footer: AntFooter} = Layout;
const {Paragraph} = Typography


const MapLocation = styled.iframe`
    width: 100%;
    height: 100%;
    min-height: 300px;
`

const StyledAntFooter = styled(AntFooter)`
    min-height: 250px;
    padding: 60px 34px;
    background: ${GRAY_2};
    position: relative;
`
const ImageLogo = styled.img`
    //margin-top: 15px;
    width: 100%;
    height: 80px;
    object-fit: contain;
    max-width: min-content;
`


const Resume = styled(Paragraph)`
    color: black;
    font-size: 18px;
    //font-weight: bold;
`
const LinksContainer = styled(Space)`
    & a {
        color: ${GREE_1};
        font-weight: bold;
        font-size: 16px;

    }

    & a:before {
        content: '-';
        padding-right: 8px;

    }
`
const Link = styled.a`
    font-size: 16px;
    font-weight: bold;
    Color: white;
`

const SocialContainer = styled.div`
    border: 2px solid ${GREE_1};
    border-radius: 4px;
    padding: 16px;

    & svg {
        color: ${GREE_1};
        height: 24px;
        width: 24px;
    }

    &:hover {
        background: ${GREE_1};

        svg {
            color: ${GRAY_2};
        }
    }
`

const CopyContainer = styled.div`
    width: 100%;
    color: white;
    background: ${GREE_1};
    padding: 16px;
    text-align: center;
    margin: 0 auto;
`


export default function Footer() {
    return (
        <>
            <Section id={'apoiador'}>
                <ImageBackground src={BannerWhite}/>
                <ContentContainer>
                    <Row gutter={[24, 8]}>
                        <Col xs={24}>
                            <MainTitle style={{margin: 0}}>Seja nosso
                                Apoiador</MainTitle>
                        </Col>
                        <Col xs={24} md={12} style={{marginTop: 16}}>
                            <Text>
                                Você pode doar qualquer quantia para que
                                possamos seguir ajudando nossos pacientes a ter
                                acesso terapêutico de qualidade
                                independentemente de sua condição econômica e
                                social, ademais, todos os nossos esforços e
                                recursos são também utilizados no desenvolvendo
                                de pesquisas e parcerias em prol do uso
                                medicinal da Cannabis e no desenvolvimento de
                                outros fitofármacos oriundos de plantas da
                                nossa Caatinga.
                            </Text>
                            <Text>
                                Somos uma associação sem fins lucrativos. Todo
                                recurso gerado pelas ações do nosso centro, são
                                obrigatoriamente investidos em prol dos nossos
                                associados e dos objetivos do centro.
                            </Text>
                            <Text>
                                Na forma de estudos científicos, ações sociais
                                de acolhimento, assistência e reabilitação dos
                                nossos associados e no constante aprimoramento
                                da qualidade de nossos produtos e dos serviços
                                prestados.
                            </Text>
                            <Text>
                                Depósitos podem ser realizados na conta do
                                CEBRAPCAM - Centro de Pesquisa e Estudos da
                                Cannabis Medicinal
                            </Text>
                        </Col>
                        <Col xs={24} md={12}>
                            <Row gutter={[8, 8]}>
                                <Col xs={24} lg={12}>
                                    <Image width={'100%'} src={QRCodeImage}/>
                                </Col>
                                <Col xs={24} lg={12}>
                                    <Text style={{marginBottom: 0}}>SICRED
                                        EVOLUÇÃO</Text>
                                    <Text
                                        style={{marginBottom: 0}}>AG: <strong>2201</strong></Text>
                                    <Text
                                        style={{marginBottom: 0}}>CNPJ: <strong>49.172.498/0001-69</strong></Text>
                                    <Text
                                        style={{marginBottom: 0}}>PIX: <strong>49.172.498/0001-69</strong></Text>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </ContentContainer>
            </Section>

            <StyledAntFooter>
                <ImageBackground src={ImageLocationBG}/>
                <ContentContainer>
                    <ContentContainer>
                        <Row gutter={[8, 8]}>
                            <Col xs={24} lg={15}>
                                <MainTitle style={{margin: 0}}>Nossa
                                    localização</MainTitle>
                                <Row style={{marginTop: 16}} gutter={[16, 16]}>
                                    <Col xs={24} md={12}>
                                        {/*<NavLink to={'/'}>*/}
                                        <ImageLogo src={Logo}/>
                                        {/*</NavLink>*/}

                                        <Space style={{marginTop: 32}}>

                                            {/*<NavLink to={"#"} target={'_blank'}>*/}
                                            {/*    <SocialContainer>*/}
                                            {/*        <FacebookFilled*/}
                                            {/*            twoToneColor={"#eb2f96"}/>*/}
                                            {/*    </SocialContainer>*/}
                                            {/*</NavLink>*/}


                                            <NavLink to={URL_INSTAGRAM}
                                                     target={'_blank'}>
                                                <SocialContainer>
                                                    <InstagramFilled/>
                                                </SocialContainer>
                                            </NavLink>

                                            <NavLink
                                                target={'_blank'}
                                                to={URL_WHATSAPP}>
                                                <SocialContainer>
                                                    <WhatsAppOutlined/>
                                                </SocialContainer>
                                            </NavLink>
                                            <NavLink to={URL_YOUTUBE}
                                                     target={'_blank'}>
                                                <SocialContainer>
                                                    <YoutubeFilled/>
                                                </SocialContainer>
                                            </NavLink>
                                            <NavLink to={URL_TWITTER}
                                                     target={'_blank'}>
                                                <SocialContainer>
                                                    <TwitterOutlined/>
                                                </SocialContainer>
                                            </NavLink>

                                        </Space>
                                        <NavLink to={URL_WHATSAPP}   target={'_blank'}>
                                            <Button style={{marginTop: 16}}
                                                    size={"large"} block
                                                    type={"primary"}
                                            >Acolhimento</Button></NavLink>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <Resume
                                            style={{marginBottom: 0}}> Somos um
                                            Centro de Pesquisas e Estudos da
                                            Cannabis que tem como objetivos
                                            principais a construção de uma
                                            sociedade solidária, com redução
                                            das desigualdades sociais e o
                                            fortalecimento da agricultura
                                            familiar.</Resume>

                                    </Col>
                                    <Col xs={24}>
                                        <Row>
                                            <Col xs={24} sm={8}>
                                                <ContanctIcon
                                                    text={'(83)9 8215-5152'}
                                                    title={'Telefone'}
                                                    icon={<PhoneFilled/>}/>
                                            </Col>
                                            <Col xs={24} sm={8}>

                                                <ContanctIcon
                                                    text={'cebrapcam@gmail.com'}
                                                    title={'Email'}
                                                    icon={<MailFilled/>}/>
                                            </Col>
                                            <Col xs={24} sm={8}>
                                                <ContanctIcon
                                                    text={'Rua Salustiano Batista De Lucena, S\\n, São José De Espinharas, Região Metropolitana de Patos-PB'}
                                                    title={'Endereço'}
                                                    icon={<ShopFilled/>}/>
                                            </Col>


                                        </Row>
                                    </Col>

                                </Row>
                            </Col>
                            <Col xs={24} lg={9}>
                                <MapLocation
                                    src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d10544.231888386761!2d-37.33030969563985!3d-6.845114038603665!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNsKwNTAnMzcuNSJTIDM3wrAxOSczNC4xIlc!5e0!3m2!1spt-BR!2sbr!4v1685638180775!5m2!1spt-BR!2sbr"
                                    allowFullScreen="" loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                />


                            </Col>
                            <Col xs={24}>


                            </Col>

                        </Row>
                    </ContentContainer>
                </ContentContainer>

            </StyledAntFooter>
            <CopyContainer>
                © Todos os direitos reservados - Desenvolvido por <Link
                href={'https://www.codtechdevelopers.com.br'}
                target={'_blank'}> Codtech Developers</Link>
            </CopyContainer>
        </>
    )
}
