import React from 'react';
import styled from "styled-components";
import {Button} from "antd";
import {GREE_1} from "../utils/colors";


const Image = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: right;
  top: 0;
  left: 0;
  z-index: 0;


`
export default function ImageBackground({children, icon, ...props}) {


    return (
        <Image {...props}/>
    )
}