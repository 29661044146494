export const DOMAIN = process.env['REACT_APP_ENDPOINT']

export async function get(url, header = {}, version = 'v1') {

    const response = await fetch(`${DOMAIN}/${version}/${url}`)
    const json = await response.json()
    return {
        data: json,
        response
    }

}

export async function post(url, body, version = 'v1') {

    const response = await fetch(`${DOMAIN}/${version}/${url}`, {
        method: "POST",
        body: body
    })

    const json = await response.json()
    return {
        data: json,
        response
    }
    // } catch (error) {
    //     console.log(error)
    // }
}