import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import Title from "../components/Title";
import {
    Col,
    Empty,
    Row,
    Space,
    Spin,
} from "antd";
import SubBanner from "../components/SubBanner";
import ContentContainer from "../components/ContentContainer";
import Section from "../components/Section";
import {useParams} from "react-router-dom";
import SubTitle from "../components/SubTitle";
import CardRecentNews from "../components/CardRecentNews";
import Image from "../components/Image";
import DateNews from "../components/DateNews";
import {UserOutlined} from "@ant-design/icons";
import * as api from "../services/api";
import {DOMAIN} from "../services/api";

const StyledSection = styled(Section)`
  padding-top: 24px;
  padding-bottom: 24px;

`
const StyledContent = styled.div`
  width: 100%;

  * {
    white-space: pre-line;

  }

  img {
    max-width: 100%;
    object-fit: cover;
    object-position: center;
  }

`

const StyledVideoDiv = styled.div`
  * {
    max-width: 100%;
  }

`

export default function News() {
    const {slug} = useParams()
    const [isLoading, setIsLoading] = useState(true)
    const [news, setNews] = useState({})
    const [recentNews, setRecentNews] = useState([])
    const contentRef = useRef()

    useEffect(() => {
        async function fetchNews(page) {
            setIsLoading(true)
            const {data} = await api.get(`news/${slug}`)
            setIsLoading(false)
            setNews(data)
        }

        async function fetchRecentNews() {
            const {data} = await api.get(`news/${slug}/recent_news`)

            setRecentNews(data)
        }

        fetchNews(1)
        fetchRecentNews()
    }, [slug])


    useEffect(() => {

        if (news.id && contentRef) {
            contentRef.current.querySelectorAll('img[src]').forEach((img) => {
                console.log(img)
                const src = img.src.split('/media/')
                console.log(`${DOMAIN}/media/${src[1]}`)
                img.src = `${DOMAIN}/media/${src[1]}`

            })

        }
    }, [news, contentRef])

    return (
        <>
            <SubBanner title={'Notícia'}/>
            <StyledSection>
                <ContentContainer>
                    <Spin spinning={isLoading}>


                        <Row gutter={[24, 24]}>
                            <Col xs={24} sm={18}><Image
                                src={news.banner}/>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Row gutter={[0, 16]}>
                                    <Col xs={24}
                                         style={{marginBottom: 0}}><SubTitle>Notícias
                                        recente</SubTitle></Col>
                                    {recentNews.length > 0 ? (
                                        recentNews.map(({
                                                            banner,
                                                            created_at,
                                                            title,
                                                            slug
                                                        }) => (
                                            <Col xs={24}>
                                                <CardRecentNews
                                                    slug={slug}
                                                    date={created_at}
                                                    title={title}
                                                    image={banner}/></Col>

                                        ))
                                    ) : (
                                        <Col xs={24}>
                                            <Empty
                                                description={'Sem notícias recentes'}/>
                                        </Col>

                                    )}


                                </Row>
                            </Col>
                            <Col xs={24}>
                                <Space direction={'vertical'} size={0}>
                                    <Title>{news.title}</Title>
                                    <Space size={24}>
                                        <Space>
                                            <UserOutlined/>
                                            <span>{news?.owner?.get_full_name}</span>
                                        </Space>

                                        <DateNews
                                            value={news.created_at}/>
                                    </Space>

                                </Space>
                            </Col>
                            <Col xs={24} style={{marginTop: 24}}>
                                <StyledContent ref={contentRef}
                                               dangerouslySetInnerHTML={{__html: news.content}}/>

                            </Col>
                            {news.video &&
                                <Col xs={24}>
                                    <StyledVideoDiv
                                        dangerouslySetInnerHTML={{__html: news.video}}/>

                                </Col>}
                        </Row>

                    </Spin>
                </ContentContainer>
            </StyledSection>


        </>
    )
}


