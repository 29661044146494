import React, {useState} from 'react';
import styled from 'styled-components';
import {UploadOutlined} from '@ant-design/icons';
import {
    Button,
    Col,
    Form,
    Input,
    Radio,
    Row, Spin,
    Typography, Upload
} from "antd";
import SubBanner from "../components/SubBanner";
import ContentContainer from "../components/ContentContainer";
import Section from "../components/Section";
import {Datepicker} from "../components/Datepicker";
import moment from "moment/moment";
import {post} from "../services/api";
import {Notification} from "../notification";
import {createFormDataByJson} from "../utils/utils";
import ConsentimentoPDF
    from '../assets/pdf/Termo_de_Consentimento_Livre_e_Esclarecido.pdf'
import ConsentimentoWORD
    from '../assets/word/Termo_de_Consentimento_Livre_e_Esclarecido.docx'
import AjuizamentoPDF
    from '../assets/pdf/Termo_de_Ajuizamento_CEBRAPCAM.pdf'
import AjuizamentoWORD
    from '../assets/word/Termo_de_Ajuizamento_CEBRAPCAM.docx'
import {URL_WHATSAPP} from "../settings";


const {Paragraph} = Typography
const StyledSection = styled(Section)`
  padding-top: 24px;
`

const CATEGORY_NAME = 'category'
const IS_CLIENT_NAME = 'is_client'
const IS_CANNABIS_NAME = 'is_cannabis'
const HAS_MEDICAL_REPORT = 'has_medical_report'
const HAS_MEDICAL_PRESCRIPTION = 'has_medical_prescription'

export default function AssociatePatient() {
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false)

    const [showClientForm, setShowClientForm] = useState(false)
    const [showClientName, setShowClientName] = useState(false)
    const [showConcentrationField, setShowConcentrationField] = useState(false)
    const [showFileMedicalReport, setShowFileMedicalReport] = useState(false)
    const [showFileMedicalPrescription, setShowFileMedicalPrescription] = useState(false)


    function handleError({values, errorFields, outOfDate}) {
        Notification('error', 'Alguns campos são obrigatorios.')
    }

    function handleFormChange(changedFields, allFields) {

        const item = changedFields[0]
        const name = item.name[0]
        if (name === CATEGORY_NAME) {
            setShowClientForm(item.value === 'paciente/responsavel')
        }
        if (name === IS_CLIENT_NAME) {

            setShowClientName(!item.value)
        }
        if (name === IS_CANNABIS_NAME) {
            setShowConcentrationField(item.value)
        }
        if (name === HAS_MEDICAL_REPORT) {
            setShowFileMedicalReport(item.value)
        }
        if (name === HAS_MEDICAL_PRESCRIPTION) {
            setShowFileMedicalPrescription(item.value)
        }
    }


    async function onSubmit(values) {
        try {
            setIsLoading(true)
            const formData = createFormDataByJson(values)
            formData.append('birth', moment(values.birth).format('YYYY-MM-DD'))
            if (values.client_birth) {
                formData.append('client_birth', moment(values.client_birth).format('YYYY-MM-DD'))
            }

            if (values.medical_prescription) {
                //todo criar função para converter pdf
                formData.delete('medical_prescription')
                formData.append('medical_prescription', values.medical_prescription.file)

            }

            if (values.medical_report) {
                formData.delete('medical_report')
                formData.append('medical_report', values.medical_report.file)

            }

            if (values.filing_term) {
                formData.delete('filing_term')
                formData.append('filing_term', values.filing_term.file)

            }

            if (values.term_consent) {
                formData.delete('term_consent')
                formData.append('term_consent', values.term_consent.file)

            }

            const {response} = await post('client', formData)
            setIsLoading(false)
            if (response.status === 201) {
                Notification('success', 'Cadastro realizado com sucesso')
                form.resetFields()
            } else {
                Notification('error', 'Ocorreu um problema, por favor tente novamente!')
            }
        } catch (e) {
            Notification('error', 'Ocorreu um problema, por favor tente novamente!')
        } finally {
            setIsLoading(false)
        }

    }

    return (
        <>
            <SubBanner title={'Formulário para ser um paciente associado'}/>
            <StyledSection>
                <ContentContainer>
                    <Row>
                        <Col xs={24}>
                            <Spin spinning={isLoading}>
                                <Paragraph style={{fontSize: 24}}>Os dados aqui
                                    inseridos estão protegidos pela Lei Geral
                                    de Proteção de Dados Pessoais (LGPD), Lei
                                    n° 13.709/2018. Sendo para uso exclusivo
                                    para fins estatísticos e
                                    científicos.</Paragraph>
                                <Form
                                    scrollToFirstError={true}
                                    onFinish={onSubmit}
                                    layout={'vertical'}
                                    onFinishFailed={handleError}
                                    onFieldsChange={handleFormChange}
                                    form={form}>
                                    <Row gutter={[16, 0]}>

                                        <Col xs={24} sm={4}>
                                            <Form.Item name={"name"}
                                                       rules={[
                                                           {
                                                               required: true,
                                                               message: 'Campo obrigatorio!',
                                                           }, {
                                                               max: 100,
                                                               message: 'O nome deve ter no máximo 100 caracteres.'
                                                           }
                                                       ]}
                                                       label={'Nome'}>
                                                <Input/>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={10}>
                                            <Form.Item name={"lastname"}
                                                       rules={[
                                                           {
                                                               required: true,
                                                               message: 'Campo obrigatorio!',
                                                           }, {
                                                               max: 100,
                                                               message: 'O sobrenome deve ter no máximo 100 caracteres.'
                                                           }
                                                       ]}
                                                       label={'Sobrenome'}>
                                                <Input/>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={5}>
                                            <Form.Item name={"cep"}
                                                       rules={[
                                                           {
                                                               required: true,
                                                               message: 'Campo obrigatorio!',
                                                           }, {
                                                               max: 8,
                                                               message: 'O CEP deve ter no máximo 8 caracteres.'
                                                           }
                                                       ]}
                                                       label={'CEP  (Digite apenas números)'}>
                                                <Input/>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={5}>
                                            <Form.Item name={"cpf"}
                                                       rules={[
                                                           {
                                                               required: true,
                                                               message: 'Campo obrigatorio!',
                                                           }, {
                                                               max: 11,
                                                               message: 'O CPF deve ter no máximo 11 caracteres.'
                                                           }
                                                       ]}
                                                       label={'CPF  (Digite apenas números) '}>
                                                <Input/>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={5}>
                                            <Form.Item name={"rg"}
                                                       rules={[
                                                           {
                                                               required: true,
                                                               message: 'Campo obrigatorio!',
                                                           }
                                                       ]}
                                                       label={'RG'}>
                                                <Input/>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={5}>
                                            <Form.Item name={"street"}
                                                       rules={[
                                                           {
                                                               required: true,
                                                               message: 'Campo obrigatorio!',
                                                           },
                                                           {
                                                               max: 255,
                                                               message: 'A rua deve ter no máximo 255 caracteres.'
                                                           }
                                                       ]}
                                                       label={'Rua'}>
                                                <Input/>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={2}>
                                            <Form.Item name={"number"}
                                                       rules={[
                                                           {
                                                               required: true,
                                                               message: 'Campo obrigatorio!',
                                                           },
                                                           {
                                                               max: 10,
                                                               message: 'O número deve ter no máximo 10 caracteres.'
                                                           }
                                                       ]}
                                                       label={'Número'}>
                                                <Input/>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={5}>
                                            <Form.Item name={"neighborhood"}
                                                       rules={[
                                                           {
                                                               required: true,
                                                               message: 'Campo obrigatorio!',
                                                           }, {
                                                               max: 255,
                                                               message: 'O Bairro deve ter no máximo 255 caracteres.'
                                                           }
                                                       ]}
                                                       label={'Bairro'}>
                                                <Input/>
                                            </Form.Item>
                                        </Col>

                                        <Col xs={24} sm={5}>
                                            <Form.Item name={"city"}
                                                       rules={[
                                                           {
                                                               required: true,
                                                               message: 'Campo obrigatorio!',
                                                           },
                                                           {
                                                               max: 255,
                                                               message: 'O Cidade deve ter no máximo 255 caracteres.'
                                                           }
                                                       ]}
                                                       label={'Cidade'}>
                                                <Input/>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={7}>
                                            <Form.Item name={"state"}
                                                       rules={[
                                                           {
                                                               required: true,
                                                               message: 'Campo obrigatorio!',
                                                           },
                                                       ]}
                                                       label={'Estado'}>
                                                <Input/>
                                            </Form.Item>
                                        </Col>


                                        <Col xs={24} sm={15}>
                                            <Form.Item name={"email"}
                                                       rules={[
                                                           {
                                                               required: true,
                                                               message: 'Campo obrigatorio!',
                                                           },
                                                           {
                                                               type: 'email',
                                                               message: 'Por favor, digite um email válido.'
                                                           }, {
                                                               max: 255,
                                                               message: 'O Email deve ter no máximo 255 caracteres.'
                                                           }
                                                       ]}
                                                       label={'Email'}>
                                                <Input/>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={9}>
                                            <Form.Item name={"receive_emails"}
                                                       rules={[
                                                           {
                                                               required: true,
                                                               message: 'Campo obrigatorio!',
                                                           },
                                                       ]}
                                                       label={'Receber emails sobre as atividades do nosso centro via e-mail?'}>
                                                <Radio.Group
                                                    options={[{
                                                        value: true,
                                                        label: 'Sim'
                                                    }, {
                                                        value: false,
                                                        label: 'Não'
                                                    }]}
                                                    optionType="button"
                                                    buttonStyle="solid"
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12}>
                                            <Form.Item name={"birth"}
                                                       rules={[
                                                           {
                                                               required: true,
                                                               message: 'Campo obrigatorio!',
                                                           },
                                                       ]}
                                                       label={'Data de nascimento'}>
                                                <Datepicker
                                                    placeholder={'Selecione a data'}/>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12}>
                                            <Form.Item name={"gender"}
                                                       rules={[
                                                           {
                                                               required: true,
                                                               message: 'Campo obrigatorio!',
                                                           },
                                                       ]}
                                                       label={'Gênero'}>
                                                <Radio.Group
                                                    options={[{
                                                        value: 'Masculino',
                                                        label: 'Masculino'
                                                    }, {
                                                        value: 'Feminino',
                                                        label: 'Feminino'
                                                    },
                                                        {
                                                            value: 'Pessoa não Binária',
                                                            label: 'Pessoa não Binária'
                                                        },

                                                    ]}
                                                    optionType="button"
                                                    buttonStyle="solid"
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={8}>
                                            <Form.Item name={"phone"}
                                                       rules={[
                                                           {
                                                               required: true,
                                                               message: 'Campo obrigatorio!',
                                                           },
                                                           {
                                                               max: 11,
                                                               message: 'O telefone deve ter no máximo 11 caracteres.'
                                                           }
                                                       ]}
                                                       label={'Telefone  (Digite apenas números)'}>
                                                <Input/>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={8}>
                                            <Form.Item name={"whatsapp"}
                                                       rules={[
                                                           {
                                                               required: true,
                                                               message: 'Campo obrigatorio!',
                                                           },
                                                           {
                                                               max: 11,
                                                               message: 'O Whatapp deve ter no máximo 11 caracteres.'
                                                           }
                                                       ]}
                                                       label={'WhatsApp  (Digite apenas números)'}>
                                                <Input/>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={8}>
                                            <Form.Item name={"type_contact"}
                                                       rules={[
                                                           {
                                                               required: true,
                                                               message: 'Campo obrigatorio!',
                                                           },
                                                       ]}
                                                       label={'Qual o melhor meio para contato?'}>
                                                <Radio.Group
                                                    options={[{
                                                        value: 'E-mail',
                                                        label: 'E-mail'
                                                    }, {
                                                        value: 'ligação',
                                                        label: 'Ligação'
                                                    }, {
                                                        value: 'WhatsApp',
                                                        label: 'WhatsApp'
                                                    }]}
                                                    optionType="button"
                                                    buttonStyle="solid"
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={15}>
                                            <Form.Item name={CATEGORY_NAME}
                                                       rules={[
                                                           {
                                                               required: true,
                                                               message: 'Campo obrigatorio!',
                                                           },
                                                       ]}
                                                       label={'Categoria de associado?'}>
                                                <Radio.Group
                                                    options={[{
                                                        value: 'paciente/responsavel',
                                                        label: 'Paciente ou Responsável por paciente menor de 18 anos'
                                                    }, {
                                                        value: 'pesquisador',
                                                        label: 'Sócio pesquisador'
                                                    }, {
                                                        value: 'apoiador',
                                                        label: 'Sócio apoiador'
                                                    }]}
                                                    optionType="button"
                                                    buttonStyle="solid"
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={9}>
                                            <Form.Item name={'visibility'}
                                                       rules={[
                                                           {
                                                               required: true,
                                                               message: 'Campo obrigatorio!',
                                                           },
                                                       ]}
                                                       label={'Como ficou sabendo da existência do nosso centro?'}>
                                                <Radio.Group
                                                    options={[{
                                                        value: 'Internet',
                                                        label: 'Internet'
                                                    }, {
                                                        value: 'Amigo',
                                                        label: 'Amigo'
                                                    },
                                                        {
                                                            value: 'WhatsApp',
                                                            label: 'WhatsApp'
                                                        },
                                                        {
                                                            value: 'Médico',
                                                            label: 'Médico'
                                                        },
                                                        {
                                                            value: 'Outross',
                                                            label: 'Outro'
                                                        },
                                                    ]}
                                                    optionType="button"
                                                    buttonStyle="solid"
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24}>
                                            <Paragraph style={{fontSize: 24}}>Você
                                                precisa preencher e realizar o
                                                scanner
                                                do termo de ajuizamento para
                                                nos
                                                enviar: <a
                                                    href={AjuizamentoWORD}
                                                    target={'_blank'}>Clique
                                                    aqui para baixar o
                                                    documento .docx</a> ou <a
                                                    href={AjuizamentoPDF}
                                                    target={'_blank'}>aqui para
                                                    baixar
                                                    o
                                                    documento
                                                    .pdf</a></Paragraph>
                                            <Form.Item
                                                name={'filing_term'}

                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Campo obrigatorio!',
                                                    },
                                                ]}
                                                label={'Enviar termo de ajuizamento preenchido'}>
                                                <Upload
                                                    beforeUpload={() => false}
                                                    accept={'.pdf'}
                                                    maxCount={1}
                                                >
                                                    <Button
                                                        icon={
                                                            <UploadOutlined/>}>enviar
                                                        pdf</Button>
                                                </Upload>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24}>
                                            <Paragraph style={{fontSize: 24}}>Você
                                                precisa preencher e realizar o
                                                scanner
                                                do termo de consentimento para
                                                nos
                                                enviar: <a
                                                    href={ConsentimentoWORD}
                                                    target={'_blank'}>Clique
                                                    aqui para baixar o
                                                    documento .docx</a> ou <a
                                                    href={ConsentimentoPDF}
                                                    target={'_blank'}>aqui para
                                                    baixar
                                                    o
                                                    documento .pdf</a>
                                            </Paragraph>
                                            <Form.Item
                                                name={'term_consent'}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Campo obrigatorio!',
                                                    },
                                                ]}
                                                label={'Enviar termo de consentimento preenchido'}>
                                                <Upload
                                                    beforeUpload={() => false}
                                                    accept={'.pdf'}
                                                    maxCount={1}
                                                >
                                                    <Button
                                                        icon={
                                                            <UploadOutlined/>}>enviar
                                                        pdf</Button>
                                                </Upload>
                                            </Form.Item>
                                        </Col>
                                        {showClientForm && (<>
                                            <Col xs={24} sm={4}>
                                                <Form.Item
                                                    name={IS_CLIENT_NAME}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Campo obrigatorio!',
                                                        },
                                                    ]}
                                                    label={'Responsável por paciente?'}>
                                                    <Radio.Group
                                                        options={[{
                                                            value: false,
                                                            label: 'Sim'
                                                        }, {
                                                            value: true,
                                                            label: 'Não'
                                                        },]}
                                                        optionType="button"
                                                        buttonStyle="solid"
                                                    />
                                                </Form.Item>
                                            </Col>
                                            {showClientName && (<>
                                                <Col xs={24} sm={15}>
                                                    <Form.Item
                                                        name={"client_name"}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Campo obrigatorio!',
                                                            },
                                                            {
                                                                max: 255,
                                                                message: 'O nome do paciente deve ter no máximo 255 caracteres.'
                                                            }
                                                        ]}
                                                        label={'Nome completo do paciente'}>
                                                        <Input/>
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} sm={5}>
                                                    <Form.Item
                                                        name={"client_birth"}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Campo obrigatorio!',
                                                            },
                                                        ]}
                                                        label={'Data de nascimento do paciente'}>
                                                        <Datepicker
                                                            placeholder={'Selecione a data'}/>
                                                    </Form.Item>
                                                </Col>
                                            </>)}
                                            <Col xs={24} sm={5}>
                                                <Form.Item
                                                    name={IS_CANNABIS_NAME}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Campo obrigatorio!',
                                                        },
                                                    ]}
                                                    label={'Já fez uso de fitocanabidioides?'}>
                                                    <Radio.Group
                                                        options={[{
                                                            value: true,
                                                            label: 'Sim'
                                                        }, {
                                                            value: false,
                                                            label: 'Não'
                                                        },]}
                                                        optionType="button"
                                                        buttonStyle="solid"
                                                    />
                                                </Form.Item>
                                            </Col>
                                            {showConcentrationField && (
                                                <Col xs={24} sm={19}>
                                                    <Form.Item
                                                        name={"concentration"}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Campo obrigatorio!',
                                                            },
                                                            {
                                                                max: 255,
                                                                message: 'A concentração deve ter no máximo 255 caracteres.'
                                                            }
                                                        ]}
                                                        label={'Qual a concentração?'}>
                                                        <Input/>
                                                    </Form.Item>
                                                </Col>)}

                                            <Col xs={24} sm={24}>
                                                <Form.Item
                                                    name={"condition_name"}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Campo obrigatorio!',
                                                        },

                                                        {
                                                            max: 255,
                                                            message: 'A Condição médica deve ter no máximo 255 caracteres.'
                                                        }
                                                    ]}
                                                    label={'Condição médica a que se destina o tratamento'}>
                                                    <Input/>
                                                </Form.Item>
                                            </Col>


                                            <Col xs={24} sm={5}>
                                                <Form.Item
                                                    name={HAS_MEDICAL_PRESCRIPTION}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Campo obrigatorio!',
                                                        },
                                                    ]}
                                                    label={'Já têm prescrição médica?'}>
                                                    <Radio.Group
                                                        options={[{
                                                            value: true,
                                                            label: 'Sim'
                                                        }, {
                                                            value: false,
                                                            label: 'Não'
                                                        },]}
                                                        optionType="button"
                                                        buttonStyle="solid"
                                                    />
                                                </Form.Item>
                                            </Col>
                                            {showFileMedicalPrescription && (
                                                <Col xs={24} sm={5}>
                                                    <Form.Item
                                                        name={'medical_prescription'}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Campo obrigatorio!',
                                                            },
                                                        ]}
                                                        label={'Enviar prescrição médica'}>
                                                        <Upload
                                                            beforeUpload={() => false}
                                                            accept={'.pdf'}
                                                            maxCount={1}
                                                        >
                                                            <Button
                                                                icon={
                                                                    <UploadOutlined/>}>enviar
                                                                pdf</Button>
                                                        </Upload>
                                                    </Form.Item>
                                                </Col>)}

                                            <Col xs={24} sm={5}>
                                                <Form.Item
                                                    name={HAS_MEDICAL_REPORT}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Campo obrigatorio!',
                                                        },
                                                    ]}
                                                    label={'Já têm laudo?'}>
                                                    <Radio.Group
                                                        options={[{
                                                            value: true,
                                                            label: 'Sim'
                                                        }, {
                                                            value: false,
                                                            label: 'Não'
                                                        },]}
                                                        optionType="button"
                                                        buttonStyle="solid"
                                                    />
                                                </Form.Item>
                                            </Col>
                                            {showFileMedicalReport && (
                                                <Col xs={24} sm={5}>
                                                    <Form.Item
                                                        name={'medical_report'}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Campo obrigatorio!',
                                                            },
                                                        ]}
                                                        label={'Enviar laudo'}>
                                                        <Upload
                                                            beforeUpload={() => false}
                                                            action={false}
                                                            accept={'.pdf'}
                                                        >
                                                            <Button
                                                                icon={
                                                                    <UploadOutlined/>}>enviar
                                                                pdf</Button>
                                                        </Upload>
                                                    </Form.Item>
                                                </Col>)}
                                            <Col xs={24} sm={12}>
                                                <Form.Item
                                                    name={'payment_type'}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Campo obrigatorio!',
                                                        },
                                                    ]}
                                                    label={'Como deseja contribuir com nosso centro?'}>
                                                    <Radio.Group
                                                        options={[{
                                                            value: 'PIX',
                                                            label: 'PIX'
                                                        }, {
                                                            value: 'CARTÃO',
                                                            label: 'Cartão'
                                                        },
                                                            {
                                                                value: 'BOLETO',
                                                                label: 'Boleto'
                                                            },
                                                            {
                                                                value: 'Depósito bancario',
                                                                label: 'Depósito bancario'
                                                            },
                                                        ]}
                                                        optionType="button"
                                                        buttonStyle="solid"
                                                    />
                                                </Form.Item>
                                            </Col>

                                        </>)}
                                        <Col xs={24}>
                                            <Form.Item>
                                                <Button loading={isLoading}
                                                        type="primary"
                                                        htmlType="submit">
                                                    Cadastrar
                                                </Button>
                                            </Form.Item>
                                        </Col>

                                    </Row>
                                </Form>
                            </Spin>
                        </Col>
                        <Col>
                            <Typography.Title level={2}>Para mais informações clique nos links a baixo</Typography.Title>
                        </Col>
                        <Col xs={24}>
                           <Typography.Title level={3}> <a
                                href={URL_WHATSAPP}
                                target={'_blank'}>SOU
                                ASSOCIADO QUERO SOLICITAR MEU
                                PRODUTO</a> </Typography.Title>
                        </Col>
                        <Col xs={24}>
                           <Typography.Title level={3}> <a
                                href={'https://forms.gle/CEidsWpNcV8PD3aH6'}
                                target={'_blank'}> RECLAMAÇÕES E SUGESTÕES: LINK DO SAA,RECLAMAÇÕES, PROPOSTAS ETC</a> </Typography.Title>
                        </Col>
                    </Row>

                </ContentContainer>
            </StyledSection>


        </>
    )
}


