import React from 'react';
import useBreakPoints from "../hooks/useBreakPoint";
import {Typography} from "antd";
import styled from 'styled-components'
import {GREE_1} from "../utils/colors";
const {Title:AntTitle} = Typography;

const StyledTitle = styled(AntTitle)`
  margin: 0 !important;
  color: ${GREE_1} !important;
`

export default function SubTitle({children, ...props}){
        const {isMdBreakPoint} = useBreakPoints()

    const titleLevel = isMdBreakPoint ? 3 : 4

    return (
        <StyledTitle   level={titleLevel} {...props}>
            {children}
        </StyledTitle>
    )
}