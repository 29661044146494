import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import {ConfigProvider} from "antd";
import {GREE_1} from "./utils/colors";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <ConfigProvider theme={{
                token: {
                    colorPrimary: GREE_1,
                },
            }}>
                <App/>
            </ConfigProvider>
        </BrowserRouter>
    </React.StrictMode>
);
