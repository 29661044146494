import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Col, Empty, Input, Pagination, Row, Spin, Typography} from "antd";
import SubBanner from "../components/SubBanner";
import ContentContainer from "../components/ContentContainer";
import Section from "../components/Section";
import CardProduct from "../components/CardProduct";
import ProductsSidebar from "../components/ProductsSidebar";
import * as api from "../services/api";

const {Search} = Input;
const {Paragraph} = Typography

const StyledSection = styled(Section)`
  padding-top: 24px;

`

const PAGE_SIZE = 10
export default function Products() {

    const [products, setProducts] = useState([])
    const [filterCategory, setFilterCategory] = useState('')

    // todo adicionar badge e um clear no filtro de cateria
    const [isLoading, setIsLoading] = useState(true)
    const [search, setSearch] = useState('')

    useEffect(() => {
        fetchProducts(1)
    }, [search, filterCategory])


    async function fetchProducts(page) {
        try {
            setIsLoading(true)
            const {data} = await api.get(`products?limit=${PAGE_SIZE}&offset=${page - 1}&search=${search}&category=${filterCategory}`)
            setProducts(data)
        } finally {
            setIsLoading(false)

        }


    }


    function handleCategoryClick(value) {

        if (value) {
            setFilterCategory(value.id)
        } else {
            setFilterCategory('')
        }
    }

    return (
        <>
            <SubBanner title={'Produtos'}/>
            <StyledSection>
                <ContentContainer>
                    <Row gutter={[16, 16]}>
                        <Col xs={{span: 12, order: 1}} offset={12}>
                            <Search
                                size={'large'}
                                allowClear
                                loading={isLoading}
                                onSearch={(value) => setSearch(value)}
                                placeholder="Digite o nome do produto aqui..."/>
                        </Col>
                        <Col xs={{span: 24, order: 3}}
                             md={{span: 6, order: 2}}>
                            <ProductsSidebar
                                onCategoryClick={handleCategoryClick}/>

                        </Col>
                        <Col xs={{span: 24, order: 2}}
                             md={{span: 18, order: 3}}>
                            <Spin spinning={isLoading} size={'large'}>
                                {products != null && products.results?.length > 0 ? (<>
                                    <Row gutter={[24, 24]} align={'start'}>
                                        {products.results.map(({
                                                                   name,
                                                                   price,
                                                                   main_picture,
                                                                   slug
                                                               }) => (
                                            <>
                                                <Col xs={24} md={12} lg={8}>
                                                    <CardProduct
                                                        price={price}
                                                        name={name}
                                                        slug={slug}
                                                        image={main_picture}/>
                                                </Col>
                                            </>

                                        ))}
                                    </Row>

                                    <Row align={'center'}
                                         style={{marginTop: 24}}><Pagination
                                        defaultCurrent={1}
                                        total={products.count}
                                        pageSize={PAGE_SIZE}
                                        hideOnSinglePage
                                        onChange={fetchProducts}
                                        responsive={false}
                                        showSizeChanger={false}/></Row>
                                </>) : (<Row align={'center'}>
                                    <Empty
                                        description={
                                            <span>{isLoading ? 'Buscando produtos...' : 'Sem produtos no momento'}</span>}/>
                                </Row>)}
                            </Spin>


                        </Col>
                    </Row>
                </ContentContainer>
            </StyledSection>

        </>
    )
}


