import React from 'react';
import PublicLayout from "../pages/layout/PublicLayout";
import {Route, Routes as ReactRoutes} from "react-router-dom";
import Home from "../pages/Home";
import Products from "../pages/Products";
import Blog from "../pages/Blog";
import News from "../pages/News";
import Product from "../pages/Product";
import NotFound from "../pages/NotFound";
import AssociatePatient from "../pages/AssociatePatient";
import AssociateMedical from "../pages/AssociateMedical";

export default function Routes() {

    return (
        <ReactRoutes>
            <Route path={'/'} element={<PublicLayout/>}>
                <Route index element={<Home/>}/>
                <Route path={'produtos'} >
                    <Route index element={<Products/>}/>
                    <Route path={':slug'} element={<Product/>}/>
                </Route>
                <Route path={'blog'}>
                    <Route index element={<Blog/>}/>
                    <Route path={':slug'} element={<News/>}/>
                </Route>
                <Route path={'associado'}>
                    <Route path={'paciente'} element={<AssociatePatient/>}/>
                    <Route path={'medico'} element={<AssociateMedical/>}/>
                </Route>
                <Route path={"*"} element={<NotFound/>}/>
            </Route>

        </ReactRoutes>
    )
}