import React from 'react';
import styled from 'styled-components';
import Title from "./Title";
import {GREE_1} from "../utils/colors";
import {Input} from 'antd';




const Banner = styled.div`
  width: 100%;
  height: 240px;
  padding: 16px;
  background: ${GREE_1};
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyleTitle = styled(Title)`
  color: white !important;
`


export default function SubBanner({title}) {

    return (

            <Banner>
                <StyleTitle>{title}</StyleTitle>
            </Banner>

    )
}