import React from 'react';
import styled from "styled-components";
import DotsIcons from "../assets/imgs/dots.png";


const Container = styled.div`
  width: min-content;
  height: 50px;
  
  display: flex;
  gap: 10px;
`
export default function DotIcons(props) {


    return (
        <Container {...props}>
            <img src={DotsIcons}/>
            <img src={DotsIcons}/>
            <img src={DotsIcons}/>
            <img src={DotsIcons}/>
        </Container>
    )
}