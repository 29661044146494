import React, {useEffect, useState} from 'react';
import styled from 'styled-components'
import {GRAY_1, GRAY_3, GREE_1, GREE_2} from "../utils/colors";
import {EyeOutlined} from '@ant-design/icons'
import SubTitle from "./SubTitle";
import {Col, Divider, Empty, Row, Space, Spin} from "antd";
import * as api from "../services/api";


const Container = styled.div`
  height: min-content;
  width: 100%;

  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 10px 40px rgb(74 74 74 / 25%);

`

const TitleContainer = styled.div`
  background: ${GREE_1};
  padding: 8px 16px;

`

const StyledSubTitle = styled(SubTitle)`
  color: white !important;
`


const Item = styled.span`

  padding: 16px;
  display: inline-block;
  color: ${GRAY_3};
  width: 100%;
  font-weight: bold;
  border-bottom: 1px solid rgba(5, 5, 5, 0.06);
  cursor: pointer;

  ${({active}) => {
    if (active) {
      return `
              background: ${GRAY_1};
          `
    }
  }}
`


export default function ProductsSidebar({onCategoryClick}) {
    const [categories, setCategories] = useState([])
    const [currentCategory, setCurrentCategory] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        async function fetchCategories() {
            try {
                setIsLoading(true)
                const {data} = await api.get(`categories`)

                setCategories(data)
            } finally {
                setIsLoading(false)

            }
        }

        fetchCategories()
    }, [])


    function handleOnClick(value) {
        onCategoryClick && onCategoryClick(value)
        setCurrentCategory(value)
    }

    return (
        <Container>
            <TitleContainer>
                <StyledSubTitle>Categorias</StyledSubTitle>

            </TitleContainer>
            <Row>
                <Col xs={24}>
                    <Item active={currentCategory === null}
                          onClick={() => handleOnClick(null)}>Todos</Item>
                </Col>
                {isLoading ?
                    <Col xs={24}><Item style={{textAlign: 'center'}}><Spin
                        spinning={true}></Spin></Item></Col> : (
                        categories.length > 0 ? (
                            categories.map((item) => (
                                <Col xs={24}><Item
                                    active={currentCategory?.id === item.id}
                                    onClick={() => handleOnClick(item)}>{item.name}</Item></Col>
                            ))
                        ) : (
                            <Col xs={24}>
                                <Item>
                                    <Empty
                                        description={'Sem categorias no momento'}/>
                                </Item>
                            </Col>

                        )
                    )}

            </Row>
        </Container>
    )
}