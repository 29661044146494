import {Col, Row, Space} from "antd";
import React from "react";
import styled from 'styled-components';
import {GREE_1} from "../utils/colors";

const Title = styled.span`
  color: ${GREE_1};
  font-weight: bold;
`
const Text = styled.span`
  color: ${GREE_1};
`

const IconContainer = styled.span`
  color: ${GREE_1};
`

export default function ContanctIcon({title, text, icon}) {
    return (
        <Row gutter={[8,0]}>
            <Col xs={2}><IconContainer>{icon}</IconContainer></Col>
            <Col xs={22}>
                <Row gutter={[16,4]}>
                    <Col xs={24}><Title>{title}</Title></Col>
                    <Col xs={24}> <Text>{text}</Text></Col>
                </Row>
            </Col>


        </Row>
    )
}