import React from 'react';
import styled from 'styled-components';
import {Typography} from "antd";


const {Paragraph} = Typography;


const TextInfo = styled(Paragraph)`
  font-size: 18px;
  line-height: 30px;
  //text-indent: 42px;
`
export default function Text({children, ...props}) {
    return <TextInfo {...props}>{children}</TextInfo>
}
