import React from 'react'
import styled from 'styled-components'

const Animation = styled.div`
  animation-name: show;
  animation-fill-mode: backwards;
  animation-duration: 1s;
  animation-delay: ${({delay}) => delay? `${delay}s`: '0.2s'};
  
  @keyframes show {
    0% {
      opacity: 0;
      transform: translateY(-40px);
      //transition: trans;
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`

export default function ShowAnimation({delay, children}) {
    return (
        <Animation delay={delay}>
            {children}
        </Animation>
    )
}