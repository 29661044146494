import React from 'react';
import styled from 'styled-components'


const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

`


export default function({...props}) {

    return (
        <Image {...props}/>
    )
}