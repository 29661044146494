import {Col, Row, Space} from "antd";
import React from "react";
import {CalendarOutlined} from "@ant-design/icons";
import moment from "moment";


export default function DateNews({value}) {
    return (
        <Space>
            <CalendarOutlined/>
            <span>{moment(value).format('DD/MM/YYYY')}</span>
        </Space>
    )
}