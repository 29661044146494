import React from 'react';
import styled from "styled-components";



const Container = styled.div`
  width: 100%;
  background: white;
  padding-top: 60px;
  padding-bottom: 60px;
  position: relative;

`
export default function Section({children, ...props}) {


    return (
        <Container {...props}>
            {children}
        </Container>
    )
}