import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import Banner from "../components/Banner";
import ContentContainer from "../components/ContentContainer";
import Title from "../components/Title";
import AboutUsImage from '../assets/imgs/about_us.jpg';
import BenefitImage from '../assets/imgs/benefit.png';
import {Col, Row, Space, Typography} from "antd";
import {GREE_1, GREE_2} from "../utils/colors";
import BenefitItem from "../components/BenefitItem";
import CardNews from "../components/CardNews";
import Section from "../components/Section";
import * as api from "../services/api";
import ImageBackground from "../components/ImageBackground";
import BannerWhite from "../assets/imgs/bg_2.jpg";
import ImageBeneficio from "../assets/imgs/beneficio.jpg";
import ImageProdutos from "../assets/imgs/Produtos.png";
import ImageBenefit1 from "../assets/imgs/benefit_1.png";
import ImageBenefit2 from "../assets/imgs/benefit_2.png";
import ImageBenefit3 from "../assets/imgs/benefit_3.png";
import ImageBenefit4 from "../assets/imgs/benefit_4.png";
import ImageBenefit5 from "../assets/imgs/benefit_5.png";
import ImageBenefit6 from "../assets/imgs/benefit_6.png";
import ImageBenefit7 from "../assets/imgs/benefit_7.png";
import Text from "../components/Text";
import MainTitle from "../components/MainTitle";

const {Paragraph} = Typography


const Benefit = styled(Section)`

  background: ${GREE_2};

`

const ImgProduto = styled.img`
  width: 100%;
  max-width: 700px;
`


const VideoYoutube = styled.iframe`
  width: 100%;
  height: 300px;

`

const Image = styled.img`
  //height: 100%;
  width: 100%;
  height: 594px;
  position: sticky;
  top: 30px;
  object-fit: cover;
  //z-index: 0;
`


const ImageContainer = styled.div`
  width: 100%;
  height: 594px;
  position: relative;

  ::before {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 1;
    transform: translateX(-px);
    backdrop-filter: drop-shadow(2px 2px 5px rgb(0 0 0 / 0.5));
    background: rgb(216, 216, 216);
    background: linear-gradient(90deg, rgba(216, 216, 216, 1) 5%, rgba(245, 245, 245, 0) 100%);
  }

`


export default function Home() {

    const [recentNews, setRecentNews] = useState([])

    useEffect(() => {
        async function fetchRecentNews() {
            const {data} = await api.get('news/recent_news_home')

            setRecentNews(data)
        }

        fetchRecentNews()
    }, [])


    return (
        <>
            <Banner/>
            <Section>
                <ImageBackground
                    src={BannerWhite}/>
                <ContentContainer>


                    <Row gutter={[16, 16]}>

                        <Col md={12}>

                            <Row style={{
                                // background: 'white',
                                // marginTop: 64,
                                // marginLeft: -32,
                                // padding: '16px 50px'
                            }}>
                                <Col>
                                    <MainTitle>Quem somos?</MainTitle>
                                </Col>
                                <Col>

                                    <Text>
                                        Somos o Centro de Pesquisa e Estudos da Cannabis Medicinal que tem como objetivos
                                        principais a construção de uma
                                        sociedade solidária, com redução
                                        das
                                        desigualdades sociais, o
                                        fortalecimento
                                        da agricultura familiar e
                                        agroecológica, a política pública
                                        de
                                        saúde e demais direitos sociais que
                                        são
                                        fundamentais para toda e qualquer
                                        pessoa que necessite e tenha
                                        indicação
                                        médica de tratamento à base de
                                        compostos derivados da Cannabis.
                                    </Text>
                                    <Text>
                                        Temos o intuito de garantir,
                                        consolidar e expandir a inclusão
                                        social, respeitando os direitos
                                        humanos dos/as pacientes de
                                        Cannabis Sativa L. Medicinal, nas
                                        suas diversas expressões, na busca
                                        pela criação de uma cultura
                                        acolhimento, autonomia e superação
                                        dos preconceitos enraizados,
                                        infelizmente, na nossa sociedade,
                                        garantindo assim a convivência
                                        saudável e aceitação incondicional
                                        dos/as pacientes por parte de todas
                                        as pessoas e segmentos sociais.
                                    </Text>
                                    <Text>
                                        Promover a educação, disseminação de conhecimentos populares e sistematizados acerca do uso medicinal da Cannabis. E assim, a consequente conscientização da sociedade quanto ao acesso, benefícios e possibilidades terapêuticas decorrentes da utilização do potencial medicinal da Cannabis. E além disso, atuar por conta própria ou em parcerias com entidades da sociedade civil organizada nacionalmente e internacionalmente, e junto ao Poder Público.
                                    </Text>

                                </Col>
                            </Row>
                        </Col>
                        <Col md={12}>
                            {/*<ImageContainer>*/}
                            <Image src={AboutUsImage}/>
                            {/*</ImageContainer>*/}
                        </Col>
                    </Row>

                </ContentContainer>
            </Section>

            <Benefit>
                <ImageBackground
                    src={ImageBeneficio}/>
                <ContentContainer>
                    <Row gutter={[16, 32]}>
                        <Col xs={12}>
                            <MainTitle>Benefícios
                                dos nossos produtos</MainTitle>

                        </Col>
                        <Col xs={24}>
                            <ImgProduto src={ImageProdutos}/>
                        </Col>
                        <Col xs={24}>
                            <Row gutter={[16, 16]}>
                                <Col flex={'auto'}>
                                    <BenefitItem
                                        icon={ImageBenefit1}
                                        color={'#82c34d'}
                                        title={'Ajuda a reduzir sintomas da dependência química.'}/>
                                </Col>

                                <Col flex={'auto'}>
                                    <BenefitItem
                                        icon={ImageBenefit2}
                                        color={'#fcb92d'}
                                        title={'Melhora quadros de epilepsia.'}/>
                                </Col>
                                <Col flex={'auto'}>
                                    <BenefitItem
                                        icon={ImageBenefit3}
                                        color={'#0098db'}
                                        title={'Auxilia nas sequelas do câncer.'}/>
                                </Col>
                                <Col flex={'auto'}>
                                    <BenefitItem
                                        icon={ImageBenefit4}
                                        color={'#006bb3'}
                                        title={'Alivia a dor.'}/>
                                </Col>
                                <Col flex={'auto'}>
                                    <BenefitItem
                                        icon={ImageBenefit5}
                                        color={'#a9518b'}
                                        title={'"Controla" ansiedade e ataques de pânico.'}/>
                                </Col>
                                <Col flex={'auto'}>
                                    <BenefitItem
                                        icon={ImageBenefit6}
                                        color={'#f48735'}
                                        title={'Auxilia na neuromodulação e em doenças neurodegenerativas. '}/>
                                </Col>
                                <Col flex={'auto'}>
                                    <BenefitItem
                                        icon={ImageBenefit7}
                                        color={'#eb3237'}
                                        title={'Melhora quadros do espectro autista e patologias relacionadas ao neurodesenvolvimento.'}/>
                                </Col>
                            </Row>
                        </Col>

                    </Row>
                </ContentContainer>
            </Benefit>
            {recentNews.length > 0 && <Section>
                <ContentContainer>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} style={{marginBottom: 32}}>
                            <MainTitle>Noticias
                                recentes </MainTitle>
                        </Col>
                        <Col xs={24}>
                            <Row gutter={[24, 24]} align={'center'}>
                                {recentNews.map(({
                                                     title,
                                                     created_at,
                                                     resume,
                                                     banner,
                                                     slug
                                                 }) => (
                                    <Col xs={24} sm={12} lg={8}>
                                        <CardNews
                                            title={title}
                                            created_at={created_at}
                                            resume={resume}
                                            slug={slug}
                                            image={banner}/></Col>

                                ))}
                            </Row>

                        </Col>
                    </Row>
                </ContentContainer>
            </Section>}
            {/*<Benefit>*/}
            {/*    <ContentContainer>*/}
            {/*        <Row gutter={[24, 24]}>*/}
            {/*            <Col xs={24} style={{marginBottom: 32}}>*/}
            {/*                <MainTitle style={{margin: '0 auto'}}>Descubra*/}
            {/*                    mais sobre nós</MainTitle>*/}
            {/*            </Col>*/}
            {/*            /!*<Col xs={24} >*!/*/}
            {/*            /!*    <VideoYoutube*!/*/}
            {/*            /!*        styled={{height: 400}}*!/*/}
            {/*            /!*            src="https://www.youtube.com/embed/mXKEjTcrkP4"*!/*/}
            {/*            /!*            title="YouTube video player"*!/*/}
            {/*            /!*            frameBorder="0"*!/*/}
            {/*            /!*            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"*!/*/}
            {/*            /!*            allowFullScreen></VideoYoutube>*!/*/}
            {/*            /!*</Col>*!/*/}
            {/*            /!*<Col xs={12}>*!/*/}
            {/*            /!*    <VideoYoutube*!/*/}
            {/*            /!*            src="https://www.youtube.com/embed/mXKEjTcrkP4"*!/*/}
            {/*            /!*            title="YouTube video player"*!/*/}
            {/*            /!*            frameBorder="0"*!/*/}
            {/*            /!*            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"*!/*/}
            {/*            /!*            allowFullScreen></VideoYoutube>*!/*/}
            {/*            /!*</Col>*!/*/}
            {/*            /!*<Col xs={12}>*!/*/}
            {/*            /!*    <VideoYoutube*!/*/}
            {/*            /!*            src="https://www.youtube.com/embed/mXKEjTcrkP4"*!/*/}
            {/*            /!*            title="YouTube video player"*!/*/}
            {/*            /!*            frameBorder="0"*!/*/}
            {/*            /!*            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"*!/*/}
            {/*            /!*            allowFullScreen></VideoYoutube>*!/*/}
            {/*            /!*</Col>*!/*/}
            {/*        </Row>*/}
            {/*    </ContentContainer>*/}
            {/*</Benefit>*/}


        </>
    )
}


