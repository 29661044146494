import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import Title from "../components/Title";
import {
    Button,
    Col, Divider,
    Image,
    Row,
    Spin,
    Typography
} from "antd";
import {GRAY_3, GREE_1, GREE_2} from "../utils/colors";
import SubBanner from "../components/SubBanner";
import ContentContainer from "../components/ContentContainer";
import Section from "../components/Section";
import {ShoppingCartOutlined} from '@ant-design/icons'
import * as api from "../services/api";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {URL_WHATSAPP} from "../settings";

const {Paragraph} = Typography

const StyledSection = styled(Section)`
  padding-top: 24px;

`

const Price = styled.span`
  font-size: 36px;
  font-weight: bold;
  color: ${GREE_1};
`
const StyledTitle = styled(Title)`
  margin-top: 0px;
  margin-bottom: 0px !important;
  color: ${GRAY_3} !important;
  //background: red;
`

const StyledTitleDescription = styled(Title)`
//text-align: center;
  white-space: nowrap;
  width: min-content;

  &:after {
    content: '';
    margin-top: 8px;
    width: 75px;
    height: 2px;
    display: block;
    background: ${GREE_1};
  }
`

const StyledParagraph = styled(Paragraph)`
  color: #75717A;
  font-weight: 500;
  line-height: 30px;
  font-size: 18px;
`


const StyledImage = styled(Image)`
  object-fit: cover;
  object-position: center;
`

export default function Product() {
    const {slug} = useParams()
    const navigate = useNavigate()
    const description = useRef()
    const [product, setProduct] = useState({})
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {

        async function fetchProduct() {
            setIsLoading(true)
            const {data, response} = await api.get(`products/${slug}`)
            setIsLoading(false)
            if (response.status === 200) {

                setProduct(data)
            } else {
                navigate('/')
            }
        }

        fetchProduct()
    }, [])




    return (
        <>
            <SubBanner
                title={isLoading ? 'Buscando produto...' : product.name}/>
            <StyledSection>
                <ContentContainer>
                    <Row gutter={[24, 24]}>
                        {isLoading ? (
                            <Col xs={24}><Row align={'center'}><Spin
                                spinning={true}/></Row></Col>) : (<>
                            <Col xs={24} md={12}>

                                <Row gutter={[0, 16]}>
                                    <Image.PreviewGroup>
                                        <Col xs={24}>
                                            <StyledImage
                                                height={500}
                                                width={'100%'}
                                                src={product.main_picture}/>
                                        </Col>
                                        <Row gutter={[16, 16]}>
                                            <Col xs={12}>
                                                <StyledImage
                                                    height={'100%'}
                                                    src={product.first_picture}/>
                                            </Col>
                                            <Col xs={12}>
                                                <StyledImage
                                                    height={'100%'}
                                                    src={product.second_picture}/>
                                            </Col>

                                        </Row>
                                    </Image.PreviewGroup>

                                </Row>
                            </Col>
                            <Col xs={24} md={12}>
                                <Row gutter={[0, 16]}>
                                    <Col xs={24}
                                         style={{marginBottom: 0}}>
                                        <StyledTitle
                                        >{product.name}</StyledTitle></Col>
                                    <Col xs={24}>
                                        <Price>R$ {product.price}</Price>
                                    </Col>
                                    <Col xs={24}>
                                        <StyledParagraph ellipsis={{
                                            rows: 6,
                                            expandable: false,
                                        }}>
                                            {product.resume}
                                        </StyledParagraph>
                                    </Col>
                                    <Col xs={24}>
                                        <NavLink
                                            to={URL_WHATSAPP}
                                            target={'_blank'}>
                                            <Button size={'large'}
                                                    type={'primary'}
                                                    shape="round"

                                                    icon={
                                                        <ShoppingCartOutlined/>}>FALE
                                                CONOSCO</Button>
                                        </NavLink>
                                    </Col>

                                </Row>
                            </Col>


                            <Col xs={24}>
                                <Row>
                                    <Col xs={24}><Divider/></Col>
                                    <Col xs={24}>
                                        <StyledTitleDescription>Descrição </StyledTitleDescription>
                                    </Col>
                                </Row>
                                 <div dangerouslySetInnerHTML={{__html: product.description}} />


                            </Col>

                        </>)}

                    </Row>
                </ContentContainer>
            </StyledSection>

        </>
    )
}


