export const URL_WHATSAPP = "https://wa.me/message/ICLEJ6SJ3GNOH1"
export const URL_INSTAGRAM = "https://www.instagram.com/cebrapcam/"
export const URL_YOUTUBE = "https://www.instagram.com/cebrapcam/"
export const URL_TWITTER = "https://twitter.com/cebrapcam"

export const NAV_ITEMS = [
    {
        key: '0',
        label: 'Início',
        to: '/'
    },
    {
        key: '1',
        label: 'Produtos',
        to: '/produtos'
    },
    {
        key: '2',
        label: 'Blog',
        to: '/blog'
    },
    {
        key: '3',
        label: 'Médico Prescritor',
        to: '/associado/medico',

    }, {
        key: '4',
        label: 'Paciente Assoc.',
        to: '/associado/paciente',

    },
    //  {
    //     key: '5',
    //     label: 'Doador',
    //     to: '/associado/paciente',
    //
    // },
    //  {
    //     key: '6',
    //     label: 'AMAR',
    //     to: '/associado/paciente',
    //
    // },
    //  {
    //     key: '7',
    //     label: 'Fale conosco',
    //     to: '/associado/paciente',
    //
    // },
];