import React, {useEffect} from 'react';
import ActionBar from "../../components/ActionBar";
import {BackTop, Layout, Space, Tag} from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import {Outlet, useLocation} from "react-router-dom";
import styled from 'styled-components';
import Footer from "../../components/Footer";
import {GREE_1} from "../../utils/colors";

const StyledLayout = styled(Layout)`
  min-height: 100vh;
`

const StyledBackTop = styled(BackTop)`
  .ant-back-top-icon {
    background: ${GREE_1};
  }
`

export default function PublicLayout() {
    const screens = useBreakpoint();
    const location = useLocation()

    useEffect(() => {
        window.scroll({top: 0})
    }, [location])
    return (
        <>
            {/*Current break point:{' '}*/}
            {/*{Object.entries(screens)*/}
            {/*    .filter((screen) => !!screen[1])*/}
            {/*    .map((screen) => (*/}
            {/*        <Tag color="blue" key={screen[0]}>*/}
            {/*            {screen[0]}*/}
            {/*        </Tag>*/}
            {/*    ))}*/}
            <StyledLayout>
                <ActionBar/>
                <Outlet/>
                <Footer/>
                <StyledBackTop>

                </StyledBackTop>
            </StyledLayout>
        </>

    )
}