import React from 'react'
import styled from "styled-components";
import {Col, Layout, Menu, Row, Space, Typography} from "antd";
import {FacebookOutlined, InstagramOutlined} from '@ant-design/icons'
import ContentContainer from "./ContentContainer";
import NavItems from "./NavItems";
import Logo from '../assets/imgs/logo.png';

import {NavLink} from "react-router-dom";
import {GRAY_2, GREE_1, GREE_2} from "../utils/colors";

const {Header} = Layout;

const padding_header = '32px';

const StyledHeader = styled(Header)`


  background: ${GRAY_2};
  
  padding: 0 0 0 ${padding_header};
  z-index: 99;
  box-shadow: 0px 5px 20px rgb(74 74 74 / 10%);
`
const ImageLogo = styled.img`
  //background: red;
  max-width: 100%;
  width: 200px;
  //margin-top: 15px;
  //height: 50px;
  //max-height: 100%;
  //width: 100%;
  //object-fit: cover;
  //height: 37px;
  //max-width: min-content;
`



const Container = styled.div`
  background: ${GREE_1};
  padding-right: ${padding_header};
  width: 100%;
  
`


const LogoContainer = styled.div`
  border-radius: 0px 16px 16px 0px;
  height: 64px;
  width: 100%;
  background: ${GRAY_2};
  display: flex;
  //justify-content: center;
  align-items: center;
  transform: translateX(12px);
  position: relative;
  z-index: 2;
`

const IconButton = styled(Space)`
  cursor: pointer;
`

export default function ActionBar() {
    return (<>
            <StyledHeader>
                <Row>

                    <Col xs={16} sm={12} md={7} lg={6}  xl={6}>
                        <NavLink to={'/'}>
                        <LogoContainer>

                                <ImageLogo src={Logo}/>

                        </LogoContainer>
                                </NavLink>
                    </Col>
                    <Col flex={'auto'}>
                        <Container>


                            <Row justify={'end'}>
                                <Col>
                                    <NavItems/>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>

            </StyledHeader>
        </>
    )
}