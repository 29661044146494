import React from 'react'
import styled from "styled-components";
import {Dropdown, Space, Typography} from "antd";
import {NavLink} from "react-router-dom";
import useBreakPoints from "../hooks/useBreakPoint";
import {BarsOutlined} from '@ant-design/icons'
import {GRAY_2, GRAY_3, GREE_1, GREE_2, GREE_3} from "../utils/colors";
import {NAV_ITEMS} from "../settings";

const {Text} = Typography;



const BurgerIcon = styled(BarsOutlined)`
  font-size: 22px;
  cursor: pointer;
  padding: 10px;
  color: ${GRAY_2};

  &:hover {
    color: rgb(16, 57, 51);
  }
`
const StyledDropdown = styled(Dropdown)`
  width: 100%;
`


const DropContainer = styled.div`
  width: 100vw;
`

const NavItem = styled(NavLink)`
  color: white;
  font-size: 18px;
  font-weight: bold;
  padding: 10px 0;

  &.active, &:hover {
    color: ${GREE_3};
    border-bottom: 2px solid currentColor;
  }
`

export default function NavItems({...props}) {

    const {isLgBreakPoint} = useBreakPoints()

    function generateDropitems() {
        return NAV_ITEMS.map(({label, to, ...options}) => (
            {
                label: <NavLink
                    to={to}>{label}</NavLink>, ...options
            }))
    }



    return (
        <Space {...props}>
            {!isLgBreakPoint ? (
                <StyledDropdown menu={{items: generateDropitems()}}
                                dropdownRender={(menu) => (
                                    <DropContainer>
                                        {menu}
                                    </DropContainer>
                                )}
                                trigger={['click']}>
                    <BurgerIcon/>
                </StyledDropdown>) : (
                <Space size={20}>
                    {NAV_ITEMS.map(({label, ...options}) =>
                        (<NavItem {...options}>{label}</NavItem>)
                    )}
                </Space>)
            }

        </Space>
    )
}