import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Col, Input, Pagination, Row, Typography, Empty, Spin} from "antd";
import SubBanner from "../components/SubBanner";
import ContentContainer from "../components/ContentContainer";
import Section from "../components/Section";
import CardNews from "../components/CardNews";
import * as api from "../services/api";

const {Search} = Input;
const {Paragraph} = Typography

const StyledSection = styled(Section)`
  padding-top: 24px;
  padding-bottom: 24px;

`

const PAGE_SIZE = 10

export default function Blog() {
    const [news, setNews] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [search, setSearch] = useState('')

    useEffect(() => {
        fetchRecentNews(1)
    }, [search])

    async function fetchRecentNews(page) {
        try {
            setIsLoading(true)
            const {data} = await api.get(`news?limit=${PAGE_SIZE}&offset=${page - 1}&search=${search}`)
            setNews(data)
        } finally {
            setIsLoading(false)

        }
    }


    return (
        <>
            <SubBanner title={'Notícias'}/>
            <StyledSection>
                <ContentContainer>
                    <Row gutter={[0, 24]}>

                        <Col xs={24} md={{offset: 12, span: 12}}>
                            <Search
                                placeholder="Pesquisar por noticias"
                                size={'large'}
                                allowClear
                                loading={isLoading}
                                onSearch={(value) => setSearch(value)}
                            />
                        </Col>
                        <Col xs={24}>
                            <Spin spinning={isLoading} size={'large'}>
                                {news != null && news.results?.length > 0 ? (<>
                                    <Row gutter={[24, 24]} align={'start'}>
                                        {news.results.map(({
                                                               title,
                                                               created_at,
                                                               resume,
                                                               banner,
                                                               slug
                                                           }) => (
                                            <>
                                                <Col xs={24} sm={12} lg={8}>
                                                    <CardNews
                                                        title={title}
                                                        created_at={created_at}
                                                        resume={resume}
                                                        slug={slug}
                                                        image={banner}/></Col>
                                            </>

                                        ))}
                                    </Row>

                                    <Row align={'center'}
                                         style={{marginTop: 24}}><Pagination
                                        defaultCurrent={1}
                                        total={news.count}
                                        hideOnSinglePage
                                        pageSize={PAGE_SIZE}
                                        onChange={fetchRecentNews}
                                        responsive={false}
                                        showSizeChanger={false}/></Row>
                                </>) : (<Row align={'center'}>
                                    <Empty
                                        description={
                                            <span>{isLoading ? 'Buscando notícias...' : 'Sem notícias no momento'}</span>}/>
                                </Row>)}
                            </Spin>

                        </Col>
                    </Row>
                </ContentContainer>
            </StyledSection>


        </>
    )
}


