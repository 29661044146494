import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 260px;
  height: 250px;
  background: ${({color}) => color ? color : 'white'};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 auto;
  & img{
    width: 100px;
    height: 100px;
    position: absolute;
    top: 0;
    transform: translateY(-50%);
  }
  
`

const Title = styled.div`
  color: white;
  font-weight: bold;
  font-size: 18px;
  width: 200px;
  text-align: center;
`

export default function BenefitItem({title, icon,...props}){


    return (
        <Container {...props}>
            <img src={icon}/>
            <Title>{title}</Title>
        </Container>
    )
}