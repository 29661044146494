import React, {useState} from 'react';
import styled from 'styled-components';
import useBreakPoints from "../hooks/useBreakPoint";
import {Button, Col, Modal, Row, Typography, Image} from "antd";
import ContentContainer from "./ContentContainer";
import BannerImage from '../assets/imgs/banner_2.jpg';
import ShowAnimation from "./animations/ShowAnimation";
import PacienteIcon from "../assets/imgs/paciente.png";
import DonateImage from "../assets/imgs/donate.png";
import ApoiadorIcon from "../assets/imgs/apoiador.png";
import {useNavigate} from "react-router-dom";
import DotIcons from "./DotIcons";
import {GREE_1} from "../utils/colors";
import ImageBackground from "./ImageBackground";

const {Paragraph} = Typography;

const Container = styled.div`
  width: 100%;

  background: gray;

  position: relative;

  ${({isMdBreakPoint}) => {
    if (isMdBreakPoint) {
      return `
            height: 600px;
            padding-top: 180px;
        `
    }
    return `
              height: 600px;
            padding-top: 30px;
      
        `
  }}
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    z-index: 1;
  }
`

const TextContainer = styled.div`
  max-width: 600px;
  margin: 0 0 0 auto;
  position: relative;
  z-index: 1;
  
    padding-top: ${({isMdBreakPoint}) => isMdBreakPoint? '0px': '125px'};
`
const StyledImageButton = styled.img`
  height: 40px;
`


const StyledTitle = styled.h1`
  color: #ededed !important;
  font-weight: 400;
  margin-top: 0;
  font-size: 32px;

  & span {
    font-size: 40px;
    font-weight: bold;
  }

`

const StyledParagraph = styled(Paragraph)`
  
  color: black !important;
  font-weight: bold;
  font-size: 20px;
`

const StyledButton = styled(Button)`
  font-weight: bold;
  font-size: 18px;
  height: 50px !important;
  display: flex;
  align-items: center;
  gap: 8px;
`
const StyledModal = styled(Modal)`
  font-weight: bold;
  font-size: 18px;
`


export default function Banner() {
    const {isMdBreakPoint} = useBreakPoints()
    const navigate = useNavigate()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalDonateOpen, setIsModalDonateOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return (

        <Container isMdBreakPoint={isMdBreakPoint}>
            <ImageBackground
                src={BannerImage}/>

            <ContentContainer>
                <TextContainer isMdBreakPoint={isMdBreakPoint}>

                    <ShowAnimation delay={0}>
                        <DotIcons style={{marginLeft: 'auto'}}/>
                        <StyledTitle><span>Bem-vindo </span>ao Centro de
                            Pesquisa e Estudos da Cannabis
                            Medicinal.</StyledTitle>
                    </ShowAnimation>
                    <ShowAnimation delay={0.5}>
                        <StyledParagraph
                            
                            // ellipsis={{rows: 4, expandable: false,}}
                            color={GREE_1}>Construir uma sociedade sem
                            preconceitos, com acesso à saúde e aos direitos
                            sociais fundamentais para todas as pessoas que
                            necessitam de um tratamento à base de derivados da
                            Cannabis.</StyledParagraph>
                    </ShowAnimation>
                    <Row style={{marginTop: 24}} gutter={[16, 16]}>
                        <Col xs={24} md={12}>
                            <ShowAnimation delay={0.7}>
                                <a href={'#apoiador'}>
                                    <StyledButton size={'large'}
                                                  type={'primary'}
                                                  block
                                                  icon={<StyledImageButton
                                                      src={PacienteIcon}/>}>APOIE O CENTRO</StyledButton>
                                </a>
                            </ShowAnimation>
                        </Col>
                        <Col xs={24} md={12}>
                            <ShowAnimation delay={0.7}>
                                <StyledButton size={'large'} type={'primary'}
                                              block
                                              onClick={showModal}
                                              icon={<StyledImageButton
                                                  src={ApoiadorIcon}/>}>ASSOCIE-SE</StyledButton>
                            </ShowAnimation>
                        </Col>
                    </Row>

                </TextContainer>

            </ContentContainer>
            <StyledModal title="O que você é?" open={isModalOpen}
                         footer={null}
                         onCancel={handleCancel}>
                <Row gutter={[16,]}>
                    <Col xs={12}>
                        <Button block type={"primary"} onClick={() => {
                            navigate('/associado/paciente')
                        }}>Paciente e pesquisador</Button>
                    </Col>
                    <Col xs={12}>
                        <Button block onClick={() => {
                            navigate('/associado/medico')
                        }}>Médico</Button>
                    </Col>
                </Row>
            </StyledModal>

            <StyledModal
                title="Você pode doar qualquer quantia para que possamos seguir ajudando nossos pacientes a ter acesso terapêutico de qualidade independentemente de sua condição econômica e social, ademais todos os nossos esforços e recursos são também utilizados no desenvolvendo pesquisas e parcerias em prol do uso medicinal da Cannabis e no desenvolvimento de outros fitofármacos oriundos de plantas da nossa caatinga."
                open={isModalDonateOpen}
                footer={null}
                width={1000}
                onCancel={() => {
                    setIsModalDonateOpen(false);
                }}>
                <Row gutter={[16,]}>
                    <Col xs={24}>
                        <Image width={'100%'} src={DonateImage}/>
                    </Col>

                </Row>
            </StyledModal>
        </Container>

    )
}
