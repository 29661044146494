import React from 'react';
import styled from 'styled-components';
import {GREE_1} from "../utils/colors";
import Title from "./Title";



const StyledTitle = styled(Title)`
  //text-align: center;
  white-space: nowrap;
  width: min-content;

  &:after {
    content: '';
    margin-top: 8px;
    width: 75px;
    height: 2px;
    display: block;
    background: ${GREE_1};
  }
`
export default function MainTitle({children, ...props}) {
    return <StyledTitle {...props}>{children}</StyledTitle>
}
