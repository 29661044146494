import React, {useState} from 'react';
import styled from 'styled-components';
import {
    Button,
    Col,
    Form,
    Input,
    Radio,
    Row, Spin, Typography
} from "antd";
import SubBanner from "../components/SubBanner";
import ContentContainer from "../components/ContentContainer";
import Section from "../components/Section";
import {Datepicker} from "../components/Datepicker";
import {post} from "../services/api";
import moment from "moment";
import {Notification} from "../notification";
import {createFormDataByJson} from "../utils/utils";
import pdf from '../assets/pdf/Como_Prescrever.pdf'

const {Paragraph} = Typography

const StyledSection = styled(Section)`
  padding-top: 24px;
`

const IS_CANNABIS_NAME = 'is_cannabis'

export default function AssociateMedical() {
    const [form] = Form.useForm();
    const [showField, setShowField] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    function handleFormChange(changedFields, allFields) {

        const item = changedFields[0]
        const name = item.name[0]

        if (name === IS_CANNABIS_NAME) {
            setShowField(item.value)
        }
    }

    async function onSubmit(values) {
        try {
            setIsLoading(true)
            const formData = createFormDataByJson(values)
            formData.append('birth', moment(values.birth).format('YYYY-MM-DD'))
            const {response} = await post('medical', formData)

            if (response.status === 201) {
                Notification('success', 'Cadastro realizado com sucesso')
                form.resetFields()
            } else {
                Notification('error', 'Ocorreu um problema, por favor tente novamente!')
            }

        } catch (e) {
            Notification('error', 'Ocorreu um problema, por favor tente novamente!')
        } finally {
            setIsLoading(false)
        }
    }

    function handleError(e) {
        console.log(e.values)
        console.log(e.errorFields)
        console.log(e.outOfDate)
        Notification('error', 'Alguns campos são obrigatorios.')
    }


    return (
        <>
            <SubBanner title={'Formulário para ser um médico associado'}/>
            <StyledSection>
                <ContentContainer>
                    <Spin spinning={isLoading}>
                        <Paragraph style={{fontSize: 24}}>Os dados aqui inseridos estão protegidos pela  Lei Geral de Proteção de Dados Pessoais (LGPD), Lei n° 13.709/2018. Sendo para uso exclusivo para fins estatísticos e científicos.</Paragraph>
                        <Paragraph style={{fontSize: 24}}>Como prescrever o medicamento?  <a href={pdf}
                                              target={'_blank'}> Clique
                                aqui</a> e saiba mais.</Paragraph>
                        <Form
                            scrollToFirstError
                            onFinishFailed={handleError}
                            onFinish={onSubmit}
                            layout={'vertical'}

                            onFieldsChange={handleFormChange}
                            form={form}>
                            <Row gutter={[16, 0]}>

                                <Col xs={24} sm={4}>
                                    <Form.Item name={"name"}
                                               rules={[
                                                   {
                                                       required: true,
                                                       message: 'Campo obrigatorio!',
                                                   },
                                                   {
                                                       max: 100,
                                                       message: 'O nome deve ter no máximo 100 caracteres.'
                                                   }
                                               ]}
                                               label={'Nome'}>
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={10}>
                                    <Form.Item name={"lastname"}
                                               rules={[
                                                   {
                                                       required: true,
                                                       message: 'Campo obrigatorio!',
                                                   },
                                                   {
                                                       max: 100,
                                                       message: 'O sobrenome deve ter no máximo 100 caracteres.'
                                                   }
                                               ]}
                                               label={'Sobrenome'}>
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={5}>
                                    <Form.Item name={"cep"}
                                               rules={[
                                                   {
                                                       required: true,
                                                       message: 'Campo obrigatorio!',
                                                   },
                                                   {
                                                       max: 8,
                                                       message: 'O CEP deve ter no máximo 8 caracteres.'
                                                   }
                                               ]}
                                               label={'CEP (Digite apenas números)'}>
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={5}>
                                    <Form.Item name={"cpf"}
                                               rules={[
                                                   {
                                                       required: true,
                                                       message: 'Campo obrigatorio!',
                                                   },
                                                   {
                                                       max: 11,
                                                       message: 'O CPF deve ter no máximo 11 caracteres.'
                                                   }
                                               ]}
                                               label={'CPF  (Digite apenas números)'}>
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={5}>
                                    <Form.Item name={"rg"}
                                               rules={[
                                                   {
                                                       required: true,
                                                       message: 'Campo obrigatorio!',
                                                   }
                                               ]}
                                               label={'RG  (Digite apenas números)'}>
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={5}>
                                    <Form.Item name={"street"}
                                               rules={[
                                                   {
                                                       required: true,
                                                       message: 'Campo obrigatorio!',
                                                   },
                                                   {
                                                       max: 255,
                                                       message: 'A rua deve ter no máximo 255 caracteres.'
                                                   }
                                               ]}
                                               label={'Rua'}>
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={2}>
                                    <Form.Item name={"number"}
                                               rules={[
                                                   {
                                                       required: true,
                                                       message: 'Campo obrigatorio!',
                                                   },
                                                   {
                                                       max: 10,
                                                       message: 'O número deve ter no máximo 10 caracteres.'
                                                   }
                                               ]}
                                               label={'Número'}>
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={5}>
                                    <Form.Item name={"neighborhood"}
                                               rules={[
                                                   {
                                                       required: true,
                                                       message: 'Campo obrigatorio!',
                                                   },
                                                   {
                                                       max: 255,
                                                       message: 'O Bairro deve ter no máximo 255 caracteres.'
                                                   }
                                               ]}
                                               label={'Bairro'}>
                                        <Input/>
                                    </Form.Item>
                                </Col>

                                <Col xs={24} sm={5}>
                                    <Form.Item name={"city"}
                                               rules={[
                                                   {
                                                       required: true,
                                                       message: 'Campo obrigatorio!',
                                                   },
                                                   {
                                                       max: 255,
                                                       message: 'O Cidade deve ter no máximo 255 caracteres.'
                                                   }
                                               ]}
                                               label={'Cidade'}>
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={2}>
                                    <Form.Item name={"state"}
                                               rules={[
                                                   {
                                                       required: true,
                                                       message: 'Campo obrigatorio!',
                                                   },

                                               ]}
                                               label={'Estado'}>
                                        <Input/>
                                    </Form.Item>
                                </Col>


                                <Col xs={24} sm={20}>
                                    <Form.Item name={"email"}
                                               rules={[
                                                   {
                                                       required: true,
                                                       message: 'Campo obrigatorio!',
                                                   },
                                                   {
                                                       type: 'email',
                                                       message: 'Por favor, digite um email válido.'
                                                   }, {
                                                       max: 255,
                                                       message: 'O Email deve ter no máximo 255 caracteres.'
                                                   }

                                               ]}
                                               label={'Email'}>
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={9}>
                                    <Form.Item name={"receive_emails"}
                                               rules={[
                                                   {
                                                       required: true,
                                                       message: 'Campo obrigatorio!',
                                                   },
                                               ]}
                                               label={'Receber informações sobre nossas atividades por e-mail?'}>
                                        <Radio.Group
                                            options={[{
                                                value: true,
                                                label: 'Sim'
                                            }, {
                                                value: false,
                                                label: 'Não'
                                            }]}
                                            optionType="button"
                                            buttonStyle="solid"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12}>
                                    <Form.Item name={"birth"}
                                               rules={[
                                                   {
                                                       required: true,
                                                       message: 'Campo obrigatorio!',
                                                   },
                                               ]}
                                               label={'Data de nascimento'}>
                                        <Datepicker/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={10}>
                                    <Form.Item name={"medical_specialty"}
                                               rules={[
                                                   {
                                                       required: true,
                                                       message: 'Campo obrigatorio!',
                                                   },
                                               ]}
                                               label={'Especialidade médica?'}>
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={7}>
                                    <Form.Item name={"crm"}
                                               rules={[
                                                   {
                                                       required: true,
                                                       message: 'Campo obrigatorio!',
                                                   },
                                               ]}
                                               label={'CRM'}>
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={7}>
                                    <Form.Item name={"crmv"}
                                               rules={[
                                                   {
                                                       required: true,
                                                       message: 'Campo obrigatorio!',
                                                   },
                                               ]}
                                               label={'CRMV'}>
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={8}>
                                    <Form.Item name={"gender"}
                                               rules={[
                                                   {
                                                       required: true,
                                                       message: 'Campo obrigatorio!',
                                                   },
                                               ]}
                                               label={'Gênero'}>
                                        <Radio.Group
                                            options={[{
                                                value: 'Masculino',
                                                label: 'Masculino'
                                            }, {
                                                value: 'Feminino',
                                                label: 'Feminino'
                                            },
                                                {
                                                    value: 'Pessoa não Binária',
                                                    label: 'Pessoa não Binária'
                                                },
                                            ]}
                                            optionType="button"
                                            buttonStyle="solid"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={8}>
                                    <Form.Item name={"phone"}
                                               rules={[
                                                   {
                                                       required: true,
                                                       message: 'Campo obrigatorio!',
                                                   },
                                                   {
                                                       max: 11,
                                                       message: 'O telefone deve ter no máximo 11 caracteres.'
                                                   }
                                               ]}
                                               label={'Telefone  (Digite apenas números)'}>
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={8}>
                                    <Form.Item name={"whatsapp"}
                                               rules={[
                                                   {
                                                       required: true,
                                                       message: 'Campo obrigatorio!',
                                                   },

                                                   {
                                                       max: 11,
                                                       message: 'O Whatsapp deve ter no máximo 11 caracteres.'
                                                   }
                                               ]}
                                               label={'WhatsApp  (Digite apenas números)'}>
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={8}>
                                    <Form.Item name={"type_contact"}
                                               rules={[
                                                   {
                                                       required: true,
                                                       message: 'Campo obrigatorio!',
                                                   },
                                               ]}
                                               label={'Qual o melhor meio para contato?'}>
                                        <Radio.Group
                                            options={[{
                                                value: 'E-mail',
                                                label: 'E-mail'
                                            }, {
                                                value: 'ligação',
                                                label: 'Ligação'
                                            }, {
                                                value: 'WhatsApp',
                                                label: 'WhatsApp'
                                            }]}
                                            optionType="button"
                                            buttonStyle="solid"
                                        />
                                    </Form.Item>
                                </Col>

                                <Col xs={24} sm={9}>
                                    <Form.Item name={'visibility'}
                                               rules={[
                                                   {
                                                       required: true,
                                                       message: 'Campo obrigatorio!',
                                                   },
                                               ]}
                                               label={'Como ficou sabendo da existência do nosso centro?'}>
                                        <Radio.Group
                                            options={[{
                                                value: 'Internet',
                                                label: 'Internet'
                                            }, {
                                                value: 'Amigo',
                                                label: 'Amigo'
                                            },
                                                {
                                                    value: 'WhatsApp',
                                                    label: 'WhatsApp'
                                                },
                                                {
                                                    value: 'Médico',
                                                    label: 'Médico'
                                                },
                                                {
                                                    value: 'Outross',
                                                    label: 'Outro'
                                                },
                                            ]}
                                            optionType="button"
                                            buttonStyle="solid"
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={5}>
                                    <Form.Item name={IS_CANNABIS_NAME}
                                               rules={[
                                                   {
                                                       required: true,
                                                       message: 'Campo obrigatorio!',
                                                   },
                                               ]}
                                               label={'Já prescreveu fitocanabinoides?'}>
                                        <Radio.Group
                                            options={[{
                                                value: true,
                                                label: 'Sim'
                                            }, {
                                                value: false,
                                                label: 'Não'
                                            },]}
                                            optionType="button"
                                            buttonStyle="solid"
                                        />
                                    </Form.Item>
                                </Col>

                                {showField &&
                                    <Col xs={24} sm={24}>
                                        <Form.Item name={"condition_name"}
                                                   rules={[
                                                       {
                                                           required: true,
                                                           message: 'Campo obrigatorio!',
                                                       },
                                                   ]}
                                                   label={'Para quais patologias?'}>
                                            <Input/>
                                        </Form.Item>
                                    </Col>}
                                <Col xs={24} sm={24}>
                                    <Form.Item name={'is_recommendation'}
                                               rules={[
                                                   {
                                                       required: true,
                                                       message: 'Campo obrigatorio!',
                                                   },
                                               ]}
                                               label={'Deseja ser indicado por nosso centro para realização de consultas a pacientes com possível necessidade de indicações de fitocanabinoides?'}>
                                        <Radio.Group
                                            options={[{
                                                value: true,
                                                label: 'Sim'
                                            }, {
                                                value: false,
                                                label: 'Não'
                                            },]}
                                            optionType="button"
                                            buttonStyle="solid"
                                        />
                                    </Form.Item>
                                </Col>

                                <Col xs={24}>
                                    <Form.Item>
                                        <Button loading={isLoading}
                                                type="primary"
                                                htmlType="submit">
                                            Cadastrar
                                        </Button>
                                    </Form.Item>
                                </Col>

                            </Row>
                        </Form>
                    </Spin>
                </ContentContainer>
            </StyledSection>

        </>
    )
}


