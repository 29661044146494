import React from 'react';
import styled from 'styled-components'
import {GREE_1} from "../utils/colors";
import {EyeOutlined} from '@ant-design/icons'
import {useNavigate} from "react-router-dom";


const Container = styled.div`
  height: 350px;
  width: 100%;

  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0px 10px 40px rgb(74 74 74 / 25%);
  cursor: pointer;
`

const Image = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  background: white;

`

const Content = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 18px;
`

const Name = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: black;
`
const Price = styled.span`
  font-size: 22px;
  font-weight: bold;
  color: ${GREE_1};
`

export default function CardProduct({slug, image, price, name}) {

    const navigate = useNavigate()

    function handleNavigation() {
        navigate(`/produtos/${slug}`)
    }

    return (
        <Container onClick={handleNavigation}>
            <Image src={image}/>
            <Content>

                <Name ellipsis={{rows: 3, expandable: false,}}>{name}</Name>
                <Price>R$ {price}</Price>
            </Content>

        </Container>
    )
}