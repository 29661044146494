import PublicLayout from "./pages/layout/PublicLayout";
import Routes from "./routes/Routes";


function App() {
    return (
        <Routes/>
    );
}

export default App;
