import React from 'react';
import useBreakPoints from "../hooks/useBreakPoint";
import {Typography} from "antd";
import styled from "styled-components";
import {GREE_1} from "../utils/colors";

const {Title: AntTitle} = Typography;

const StyledTitle = styled(AntTitle)`
  //margin: 0 !important;
  &.ant-typography{
    color: ${GREE_1};
  }
`

export default function Title({children, ...props}) {
    const {isMdBreakPoint} = useBreakPoints()

    const titleLevel = isMdBreakPoint ? 1 : 3

    return (
        <StyledTitle level={titleLevel} {...props}>
            {children}
        </StyledTitle>
    )
}