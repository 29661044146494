import React from 'react';
import styled from 'styled-components'
import {Space, Typography} from "antd";
import SubTitle from "./SubTitle";
import {GRAY_1, GREE_1, GREE_2} from "../utils/colors";
import {CalendarOutlined} from '@ant-design/icons'
import {Link} from "react-router-dom";
import DateNews from "./DateNews";

const {Paragraph} = Typography

const Container = styled.div`
  height: 540px;
  width: 100%;
  background: white;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0px 10px 40px rgb(74 74 74 / 25%);
`

const Image = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;

`

const Content = styled.div`
  padding: 22px 25px;
  height: 270px;
`
const Footer = styled.div`
  border-top: 1px solid ${GRAY_1};
  padding: 22px 25px;
`

const StyledLink = styled(Link)`
  border: 1px solid ${GRAY_1};
  padding: 8px 16px;
  color: black;
`

export default function CardNews({
                                     slug,
                                     image,
                                     title,
                                     resume,
                                     created_at
                                 }) {

    return (
        <Container>
            <Image src={image}/>

            <Content>
                <SubTitle ellipsis={{rows: 2, expandable: false,}}>{title}</SubTitle>
                <Paragraph ellipsis={{rows: 6, expandable: false,}}>{resume} </Paragraph>
                <StyledLink to={`/blog/${slug}`}>
                    Leia mais...
                </StyledLink>
            </Content>
            <Footer>
                <DateNews value={created_at}/>

            </Footer>
        </Container>
    )
}